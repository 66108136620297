<template>
  <div class="rounded-2 border border-3 p-2 shadow-lg" style="background-color: rgba(190, 169, 148, 0.5);">
    <h4 id="tablet">{{ title }}</h4>
    <iframe :src="chartUrl" class="table" height="400"></iframe>
  </div>
</template>

<script>
export default {
  name: "CurrentTable",
  data() {
    return {
      url: `https://meteo.pileus.si/embed/widget/3YCLgbHb?token=${process.env.VUE_APP_BENEDIKT_API}`,
    };
  },
  props: {
    chartUrl: String,
    title: String
  }
};
</script>