<template>
    <div class="container-fluid mb-4 mt-2">
        <div class="row mb-4">
            <div class="col-12 col-lg-5 mb-4 mb-lg-0 mb-xl-0">
                <StormChart :chartUrl="dewpt" title="Rosišče" />
            </div>
             <div class="col-12 col-lg-7">
                <StormChart :chartUrl="dir" title="Smer in sunki vetra" />
            </div>
        </div>
        <div class="row mb-4">
            <div class="col-12 col-lg-5 mb-4 mb-lg-0 mb-xl-0">
                <StormChart :chartUrl="solar" title="Sončno obsevanje" />
            </div>
             <div class="col-12 col-lg-7">
                <StormChart :chartUrl="temp" title="Potek temperature zraka" />
            </div>
        </div>
        <div class="row mb-4">
            <div class="col-12 col-lg-12">
                <StormChart :chartUrl="wind" title="Sunki in hitrost vetra" />
            </div>
        </div>
    </div>
</template>

<script>
import StormChart from '../../components/Widget/Storm/StormChart.vue';

export default {
    name: 'StormDashboard',
    components: {
        StormChart
    },
    data() {
        return {
            dewpt: `https://meteo.pileus.si/embed/widget/cGZx8W3e?token=${process.env.VUE_APP_BENEDIKT_API}`,
            dir: `https://meteo.pileus.si/embed/widget/dYrYV8nW?token=${process.env.VUE_APP_BENEDIKT_API}`,
            solar: `https://meteo.pileus.si/embed/widget/ZZchiXrz?token=${process.env.VUE_APP_BENEDIKT_API}`,
            temp: `https://meteo.pileus.si/embed/widget/I3F7pbti?token=${process.env.VUE_APP_BENEDIKT_API}`,
            wind: `https://meteo.pileus.si/embed/widget/Gh8iaRqU?token=${process.env.VUE_APP_BENEDIKT_API}`,
        }
    }
}
</script>